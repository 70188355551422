// export type Permission =
//   | "AccessDocuments"
//   | "ResetPassword"
//   | "ViewInventory"
//   | "ViewPricing"
//   | "ViewProfile"
//   | "PlaceOrder"
//   | "OrderProduct"
//   | "ViewPurchaseHistory"
//   | "ApproveSamples"
//   | "ViewApproveSamples"
//   | "ViewArchive"
//   | "ReturnForm"
//   | "BPSApproveOrders";

export type LoginResponse =
  | {
      FirstName: string;
      Token: string;
      CurrentCartID: string;
    }
  | {
      Reset: true; // ?? or string?
      Code: string;
      Email: string;
    };

export enum Permission {
  AccessDocuments = "AccessDocuments",
  ResetPassword = "ResetPassword",
  ViewInventory = "ViewInventory",
  ViewPricing = "ViewPricing",
  ViewProfile = "ViewProfile",
  PlaceOrder = "PlaceOrder",
  OrderProduct = "OrderProduct",
  ViewPurchaseHistory = "ViewPurchaseHistory",
  ApproveSamples = "ApproveSamples",
  ViewApproveSamples = "ViewApproveSamples",
  ViewArchive = "ViewArchive",
  ReturnsForm = "ReturnsForm",
  BPSApproveOrders = "BPSApproveOrders",
}

export type AccountStatus = {
  Currency: "USD" | "CAD";
  Permissions: Permission[];
};

export type AccountAddress = {
  AccountID: string;
  Name: string;
  Street: string;
  City: string;
  Region: string;
  Country: string;
  Postal: string;
};

export type AccountCommunication = {
  Name: string;
  Type: "Order Acknowledgement" | "Invoice";
  Phone: string;
  Email: string;
};

export type AccountProfile = {
  AccountID: string;
  CustomerNumber: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  Street: string;
  City: string;
  Region: string;
  Country: string;
  Postal: string;
  Permissions: Permission[];
  ShipTos: AccountAddress[];
  Related: string[];
  Representative: false | null | { Name: string; Phone: string; Email: string };
  Communications?: AccountCommunication[];
  Premiere: boolean;
  Company: Omit<AccountAddress, "Name"> & {
    Email: string;
    Phone: string;
    FirstName: string;
    LastName: string;
  };
};

export type ListOrderSummary = {
  To: string;
  From: string;
  Total: number;
  Results: OrderSummary[];
};

export type OrderSummary = {
  OrderID: string;
  PONumber: string;
  InvoiceID: string;
  Status: "paid" | "due" | "open";
  Date: string; // YYYY-MM-DD
  Amount: number;
  DueDate: string; // YYYY-MM-DD
  Currency: "USD" | "CAD";
  LineCount: number;
};

export type AccountProfileFilters = {
  bps?: boolean;
  communications?: boolean;
};

export type Document = {
  Name: string;
  URL: string;
  Categories: string[];
  LastUpdated: number;
};
